<template>
    <div class="container">
        <breadcrumb
            :loading="loading"
            :show="!showLoader"
            :items="category.parents"
            :current="category.name"
            type="category"
        />
        <b-row>
            <b-col
                v-if="show"
                class="inner-sidebar"
                :class="{
                    active: innerSidebarStatus,
                    transparent: loading && !innerSidebarStatus,
                }"
                lg="3"
                md="4"
                sm="12"
            >
                <sidebar :class="{ active: sidebarStatus }" />
            </b-col>
            <b-col
                v-if="showLoader"
                lg="3"
                md="4"
                sm="12"
                class="sidebar"
            >
                <sidebar-loader />
            </b-col>
            <product-listing
                :page-changed="pageChanged"
            />
        </b-row>
        <compare-popup
            v-if="comparePopupIsActive"
            :compare-data="toCompareData"
        />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';

    import Breadcrumb from '../components/controls/page/Breadcrumb';
    import SidebarLoader from '../components/loaders/category/SidebarLoader';
    import ProductListing from './category/ProductListing';
    import Sidebar from './category/Sidebar';

    const ComparePopup = () =>
        import(
            /* webpackChunkName: "compare-popup" */ '../components/controls/compare/ComparePopup'
        );

    export default {
        name: 'Category',
        components: {
            Sidebar,
            ProductListing,
            Breadcrumb,
            ComparePopup,
            SidebarLoader,
        },
        computed: {
            ...mapState('category', [
                'category',
                'products',
                'filtersCount',
                'categories',
                'loading',
                'updatedQuery',
                'mobileFiltersActive',
            ]),
            ...mapState('compare', ['toCompareData']),
            comparePopupIsActive() {
                return Object.keys(this.toCompareData).length > 0;
            },
            isResponsive() {
                return this.$screen.width < 768;
            },
            productsCount() {
                return this.products.elements.length;
            },
            show() {
                return (
                    this.productsCount > 0 ||
                    (this.productsCount == 0 &&
                        this.filtersCount > 0 &&
                        (this.categories.elements.length > 0 ||
                            (this.categories.elements.length == 0 && this.isResponsive)))
                );
            },
            showLoader() {
                return this.productsCount == 0 && this.loading && !this.isResponsive;
            },
        },
        data() {
            return {
                paramChanged: false,
                pageChanged: false,
                innerSidebarStatus: false,
                sidebarStatus: false,
            };
        },
        methods: {
            ...mapActions('category', [
                'getProducts',
                'updateQuery',
                'getMoreProductsInfiniteScroll',
            ]),
            async loadProducts() {
                await this.getProducts({ query: null, search: false });
            },
        },
        created() {
            if (this.$screen.breakpoint == 'md') {
                this.sidebarActive = false;
            }
            this.loadProducts();
            if (!this.updatedQuery) {
                this.pageChanged = true;
            }
        },
        watch: {
            '$route.params.slug3'() {
                this.paramChanged = true;
            },
            '$route.params.slug2'() {
                this.paramChanged = true;
            },
            '$route.params.slug1'() {
                this.paramChanged = true;
            },
            paramChanged: {
                handler(value) {
                    if (value && !this.updatedQuery) {
                        this.loadProducts();
                        this.paramChanged = false;
                        this.pageChanged = true;
                    }
                },
            },
            updatedQuery: {
                handler(value) {
                    if (value) {
                        this.loadProducts();
                        this.pageChanged = false;
                        this.paramChanged = false;
                        this.updateQuery(false);
                    } else {
                        this.updateQuery(false);
                    }
                },
            },
            loading() {
                if (!this.loading) {
                    this.pageChanged = false;
                }
            },
            mobileFiltersActive: {
                handler(value) {
                    if (value) {
                        this.innerSidebarStatus = true;
                        setTimeout(() => {
                            this.sidebarStatus = true;
                        }, 1);
                    } else {
                        this.sidebarStatus = false;
                        setTimeout(() => {
                            this.innerSidebarStatus = false;
                            document.body.classList.remove('no-scrolling');
                        }, 301);
                    }
                },
            },
        },
        metaInfo() {
            return {
                title: this.category.meta_title,
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: this.category.meta_description,
                    },
                    {
                        vmid: 'keywords',
                        name: 'keywords',
                        content: this.category.meta_keywords,
                    },
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: this.category.meta_title,
                        template: (chunk) => `${chunk} | Pescario.ro`,
                    },
                    {
                        vmid: 'og:url',
                        property: 'og:url',
                        content: window.location.origin + this.$route.fullPath,
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: this.category.meta_description,
                    },
                    {
                        vmid: 'og:image',
                        property: 'og:image',
                        content: this.category.image,
                    },
                ],
                link: [
                    {
                        rel: 'canonical',
                        href: window.location.origin + this.$route.fullPath,
                    },
                ],
            };
        },
    };
</script>
